var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('p',{staticClass:"mb-5"},[_vm._v(" Match the glassware name to its picture and determine the precision associated with each piece. ")]),_c('br'),_c('v-simple-table',[_c('thead'),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.rowNames[0]))]),_vm._l((_vm.images),function(image){return _c('td',{key:image.alt},[_c('img',{staticStyle:{"max-height":"150px"},attrs:{"src":image.img,"alt":image.alt}})])})],2),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.rowNames[1]))]),_vm._l((_vm.optionsNameShuffled),function(option,index){return _c('td',{key:option},[_c('v-select',{key:option,staticClass:"my-1",attrs:{"items":_vm.optionsNameShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}],null,true),model:{value:(_vm.inputs.nameAnswers[index]),callback:function ($$v) {_vm.$set(_vm.inputs.nameAnswers, index, $$v)},expression:"inputs.nameAnswers[index]"}})],1)})],2),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.rowNames[2]))]),_vm._l((_vm.optionsNameShuffled),function(option,index){return _c('td',{key:option},[_c('v-select',{key:option,staticClass:"my-1",attrs:{"items":_vm.optionsPrecision,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}],null,true),model:{value:(_vm.inputs.precisionAnswers[index]),callback:function ($$v) {_vm.$set(_vm.inputs.precisionAnswers, index, $$v)},expression:"inputs.precisionAnswers[index]"}})],1)})],2)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }